.filter-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    position: relative;
}

.filter-toggle {
    background-color: rgba(60, 117, 255, 0.49);
    color: #ffffff;
    border: none;
    padding: 8px 10px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.filter-toggle:hover {
    background-color: rgba(60, 117, 255, 0.49);
    transform: scale(1.1);
}

.filter-options {
    position: absolute;
    top: 120%;
    right: 0;
    background-color: #ffffff;

    padding: 15px;
    width: 250px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 10;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.filter-category h3 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.checkbox-group input {
    margin-right: 8px;
}

.checkbox-group label {
    font-size: 12px;
    color: #34495e;
}

@media (max-width: 768px) {
    .filter-toggle {
        font-size: 10px;
        width: 35px;
        height: 35px;
    }

    .filter-options {
        width: 200px;
    }
}

.search-icon{
    color: rgba(60, 117, 255, 0.49);
}

.sort-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: white;

}

.search-bar {
    width: 50%;
    font-size: 16px;
    padding: 8px;
    margin-left: -13%;
}

.sort-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 16px;
}

.sortname {
    font-weight: normal;
    font-size: 16px;
}

.container{
    font-size: 16px;
    padding: 8px;
}
