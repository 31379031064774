/* Allgemeines Styling für das Login-Container */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: white;
}

/* Styling für das Formular */
.login-form {
    background: rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

/* Überschriften */
.login-form h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
}

/* Eingabefelder */
.login-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #efebeb;
}

/* Button */
.login-form button {
    background: #3c75ff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
    width: 100%;
}

.login-form button:hover {
    background: rgba(60, 117, 255, 0.25);
}

/* Responsive Design */
@media (max-width: 768px) {
    .login-form {
        width: 90%;
    }
}
