/* Navbar */
.navbar {
    background-color: rgba(60, 117, 255, 0.49);
    color: white;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar Container */
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
}

/* Logo */
.navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
}

/* Navigation Links */
.navbar-links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.navbar-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

/* Deaktivierte Links */
.disabled-link {
    pointer-events: none;
    color: gray !important;
    opacity: 0.6;
}

.disabled-link {
    pointer-events: none;
    color: #ccc; /* Helle Farbe für deaktivierte Links */
}


/* Logout-Button */
.logout-button {
    background: #ff4b4b;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
    margin: 0px;
}

.logout-button:hover {
    background: #d43f3f;
}

/* Deaktivierter Logout-Button */
.logout-button:disabled {
    background: gray;
    cursor: not-allowed;
    opacity: 0.6;
}
