.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.layout-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff;
}

.layout-footer {
    text-align: center;
    padding: 10px 20px;
    background-color: rgba(60, 117, 255, 0.49);
    color: white;
}
