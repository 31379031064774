body {
    font-family: Arial, sans-serif;
    background-color: #f4f7fa;
    color: #333;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}


.tournament-form-container, .tournament-overview, .tournament-list {

    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding-right: 25px;

}

.tournament-form-container h2 {
    font-size: 1.5rem;
    color: black;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    font-weight: bold;
    color: #334155;
    margin-bottom: 5px;
    display: block;
}

.form-group input,
.form-group textarea {
    width: 99%;
    padding: 10px;
    border: 1px solid rgba(44, 41, 41, 0.07);
    border-radius: 8px;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.06);
}

button[type="submit"] {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px;
    background-color: white;
    color: rgba(60, 117, 255, 0.49);
    border: 2px solid rgba(60, 117, 255, 0.49);
    border-radius: 8px;

    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

button[type="submit"]:hover {
    background-color: rgba(60, 117, 255, 0.49);
    color: white;
    border: none;
}

.tournament-overview p, .tournament-list p {
    font-size: 16px;
    color: #666;
    margin: 10px 0;
}

.tournament-list-item {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}
